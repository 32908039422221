<template>
  <div class="tw-flex tw-flex-col d-flex d-sm-none">
    <div class="tw-left-0 logo tw-mt-6">
      <Logo />
    </div>
    <div class="container tw-flex tw-flex-col">
      <div class="">
        <Balloon />
      </div>
      <div class="textDisplay tw-justify-center">
        Stay tuned! Our mobile app is launching soon
      </div>
    </div>
  </div>
</template>

<script>
import Balloon from '@/assets/mobile/balloon.svg';
import Logo from '@/assets/logo/logo.svg';
export default {
  name: 'MobileScreen',
  components: {
    Balloon,
    Logo,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/grid.scss";
.container {
  justify-content: center;
  align-items: center;
  margin-top: 50%;
}
.textDisplay {
  font-family: "PoppinsMedium";
  font-size: 1rem;
  line-height: 1.375rem;
  color: #000;
  text-align: center;
  margin-top: 1rem;
}

.logo {
  width: 7rem;
  padding-left: 1rem;
}
</style>
