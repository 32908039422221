<template>
  <footer
    class="footer is-primary tw-relative tw-bottom-0 tw-w-full hidden-xs-only"
  >
    <div class="footer-container tw-w-full">
      <div class="tw-flex tw-grid-cols-2 left-container">
        <div class="tw-flex tw-flex-row tw-w-2/12 logo-container">
          <div class="tw-w-full tw-align-middle">
            <Logo class="tw-w-15 logo" />
            <!-- <div class="subscribe">SUBSCRIBE</div>
                          <div class="tw-border tw-rounded-xl tw-flex tw-justify-between tw-items-center tw-py-1 tw-px-6 tw-mt-4 tw-w-full tw-m-auto tw-h-16">
                            <div class="tw-flex tw-flex-row tw-pr-3 tw-items-center tw-text-sm">
                                <Mail class="tw-mr-2"/>
                            <div>name@email.com</div>
                            </div>
                            <button class="tw-text-white hover:tw-opacity-90 tw-bg-primary tw-rounded-full tw-py-2 tw-px-5 my-5 tw-h-7 signup">
                            Sign Up
                            </button>
                        </div> -->
          </div>
        </div>
        <div
          class="tw-flex tw-flex-row tw-grid-cols-3 tw-my-4 customer-service"
        >
          <div class="tw-invisible">
            SERVICES
            <div class="customer-service-label">Influencer Trips</div>
            <div class="customer-service-label">Destinations</div>
            <!-- <div class="customer-service-label">Flights</div>
                        <div class="customer-service-label">Accommodations</div> -->
          </div>
          <div class="">
            ABOUT
            <div class="customer-service-label ">
              <router-link class="router-text" :to="{ name: 'AboutPage' }">
                Our Story
              </router-link>
            </div>
            <div class="customer-service-label">
              <router-link class="router-text" :to="{ name: 'AboutPage' }">
                Team
              </router-link>
            </div>
            <!-- <div class="customer-service-label">Partners</div>
                        <div class="customer-service-label">Careers</div> -->
          </div>

          <div class="">
            HELP
            <!-- <div class="customer-service-label">FAQs</div> -->
            <div class="customer-service-label">
               <router-link class="router-text" :to="{ name: 'BecomeAnInfluencerPage' }">
                Become an Influencer
              </router-link>
            </div>
              <div class="customer-service-label">
              <router-link class="router-text " :to="{ name: 'ContactUs' }">
                Contact
              </router-link>
            </div>
            <div class="customer-service-label">
              <router-link class="router-text" :to="{ name: 'Feedback' }">
                Feedback
              </router-link>
            </div>
          </div>
          <div class="">
            FOLLOW US
            <div class="tw-flex tw-flex-row tw-mt-3">
              <!-- <button><Google/></button> -->
              <button class="gap" @click="facebookLink"><Facebook /></button>
              <button @click="instagramLink"><Instagram /></button>
              <!-- <button><Twitter/></button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
    <div class="breakline tw-w-full"></div>
    <div class="footer-container tw-w-full">
      <div
        class="legal-container tw-flex tw-flex-row tw-m-auto h-100 align-center"
      >
        Balloon Trips Gmbh <Copyright /><span>All rights reserved.</span>
      </div>
      <div class="tw-flex tw-flex-row tw-flex-end terms">
        <!-- <router-link class="terms-conditions" :to="{ name: 'Imprint' }">
                        Terms & Condition
                    </router-link> -->
        <router-link class="terms-conditions" :to="{ name: 'PrivacyPolicy' }">
          Privacy Policy
        </router-link>
        <router-link class="terms-conditions" :to="{ name: 'Imprint' }">
          Imprint
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/assets/logo/logo.svg';
//import Google from '@/assets/footer/Google.svg';
import Facebook from '@/assets/footer/Facebook.svg';
import Instagram from '@/assets/footer/Instagram.svg';
//import Twitter from '@/assets/footer/Twitter.svg';
import Copyright from '@/assets/footer/copyright.svg';
//import Mail from '@/assets/footer/mail.svg';
export default {
  name: 'Footer',
  components: {
    Logo,
    //Google,
    Facebook,
    Instagram,
    //Twitter,
    Copyright
    //Mail,
  },
  data: () => ({}),
  methods: {
    facebookLink() {
      window.open('https://www.facebook.com/Travelo-107178695021834/');
    },
    instagramLink() {
      window.open('https://www.instagram.com/balloon_trips/');
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';
.footer {
  background-color: rgba(128, 128, 128, 0.05);
  padding-top: 16px;
}

.footer-container {
  display: flex;
  margin-left: 2rem;
}

.origin {
  color: #252014;
}

.left-container {
  @include respond-to(lg) {
    @apply tw-gap-96;
  }
  @include respond-to(xl) {
    @apply tw-gap-96;
  }
}

.social-media-icons {
  margin-left: 1rem;
  @include respond-to(xs) {
    text-align: center;
  }
}

.logo {
  // margin-left: -5rem;
  // @include respond-to(sm) {
  //     margin-left: -5rem;
  // }
  margin-top: 27px;
}

.gap {
  @apply tw-mr-4;
}

.subscribe {
  font-family: 'MontserratBold';
  font-size: 1.05rem;
  color: #747378;
  margin-top: 3rem;
}

.logo-container {
  flex: 1;
}

.signup {
  font-size: 0.75rem;
  width: 100px;
}
.customer-service {
  color: #252014;
  font-family: 'MontserratBold';

  @include respond-to(xl) {
    margin-left: 30rem;
  }
  @include respond-to(lg) {
    margin-left: 8rem;
  }
  @include respond-to(md) {
    margin-left: 8rem;
  }
  @include respond-to(sm) {
    margin-left: 5rem;
    font-size: 0.75rem;
  }
}

.customer-service-label {
  font-family: MontserratRegular;
  margin-top: 1rem;
  color: #252014;
  @apply tw-mr-16;
  @include respond-to(sm) {
    font-size: 1rem;
    @apply tw-mr-8;
  }
}

.router-text {
  font-family: MontserratRegular;
  color: #252014;
}

.terms {
  margin-left: auto;
  color: #252014;
  font-family: 'MontserratRegular';
  font-size: 1.05rem;
  .terms-conditions {
    margin-right: 2rem;
    color: #252014;
  }
  padding-right: 4rem;
}

.legal-container {
  color: #252014;
  flex: 1;
  font-family: 'MontserratRegular';
  font-size: 1.05rem;
  height: 115px;
  line-height: 115px;
}
.terms {
  height: 115px;
  line-height: 115px;
}

.breakline {
  height: 1px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #252014;
  opacity: 0.1;

  margin: 1em 0 0 0;
  padding: 0;
}
</style>
